<template>
    <div class="order-summary">
        <div class="order-summary-box">
            <div class="order-summary-box-header">
                  <h2>סיכום הזמנה לפני שליחה</h2>
                  <div @click="$emit('close')" class="exit-btn">
                    <i class="material-icons remove-option" style="color: #fff;">close</i>
                  </div>
               
            </div>
            <div class="order-summary-box-describe">
                  <h3>תאריך הזמנה: {{new Date().toLocaleDateString('he')}}</h3>
                  <el-input v-model="comment" maxlength="50" placeholder="הערה להזמנה..." />
            </div>
            <div class="order-summary-box-content">
                <table>
                    <thead>
                      <tr>
                          <th>תמונה</th>
                          <th>שם פריט</th>
                          <th>ברקוד</th>
                          <th>כמות במארז</th>
                          <th>סך כמות</th>
                          <th>סך מחיר</th>
                          <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(item,index) in items" :key="item.barcode">
                          <tr>
                              <td>
                                  <img v-if="!item.image" style="width:50px" :src="no_items" alt="">
                                  <img v-else style="width:50px" :src="item.image" alt="">
                              </td>
                              <td>{{item.name}}</td>
                              <td>{{item.barcode}}</td>
                              <td>{{item.pac_amount}}</td>
                              <td>{{item.qnt}} מארזים</td>
                              <td>{{((Number(item.qnt) * Number(item.pac_amount))* price_per_item(item)).toFixed(2)}} &#8362;</td>
                              <td>
                                <i @click="item.qnt=0" class="material-icons remove-option" style="color: red; cursor: pointer;">close</i>
                              </td>
                          </tr>
                      </template>
                    </tbody> 
                </table>
            </div>
            <div class="order-summary-box-total">
                <h2>סך פריטים: {{total_items}}</h2>
            </div>
            <div class="order-summary-box-footer">
                  <el-button @click="handle_next_step" style="width: 80%;" type="primary">המשך</el-button>
            </div>

            <UserDetailsForm 
                @back="show_user_details_form = false"
                @submit="handle_submit"
                v-if="show_user_details_form" 
            />
        </div>

    </div>
</template>

<script>
    import {slide_pop_error} from '../../Methods/Msgs'
    import { defineAsyncComponent, ref } from 'vue';
    import { price_per_item } from '../../components/BenShlush/scripts';
   

    export default {
        props:['total_items','items'],
        emits:['close','submit'],
        components:{
            UserDetailsForm: defineAsyncComponent(() => import('./UserDetailsForm.vue'))
        },
        setup(props,{emit}){

            const comment = ref('')
            const is_panding = ref(false)
            
            const show_user_details_form = ref(false)

            const handle_next_step = () => {
                // const currentDate = new Date();
                // const firestoreTimestamp = firebase.firestore.Timestamp.fromDate(currentDate);
                // console.log(firestoreTimestamp);
                try{
                    if(props.items.length == 0){
                        throw new Error('!אינך יכול לשלוח הזמנה ריקה')
                    }
                    show_user_details_form.value = true


                    // alert_confirm('? לשלוח הזמנה זו')
                    // .then(async (res) => {
                    //     if(res.isConfirmed){
                    //         is_panding.value = true
                    //         const currentDate = new Date();
                    //         const firestoreTimestamp = firebase.firestore.Timestamp.fromDate(currentDate); 
                    //         console.log(firestoreTimestamp);
                    //         try{
                    //             const html = `
                    //                     <div dir="rtl">
                    //                     <div style="width:100%; height:50px">
                    //                         <img style="max-width:100%; max-height:100%;" src="https://firebasestorage.googleapis.com/v0/b/shmarim-41515.appspot.com/o/Logos%2Fshmarim_logo-no-bg.png?alt=media&token=2bc646b3-29b6-4cf9-8bb4-d4187bbce300">
                    //                     </div>
                    //                     <h1 style="text-align: center;">נוצרה הזמנה חדשה - מס' ${order.order_number}</h1>
                    //                     <table style="width: 100%; border-collapse: collapse; border: 1px solid black;">
                    //                         <thead>
                    //                             <tr>
                    //                                 <th style="border: 1px solid black;">מס' הזמנה</th>
                    //                                 <th style="border: 1px solid black;">שם הלקוח</th>
                    //                                 <th style="border: 1px solid black;">תאריך ההזמנה</th>
                    //                             </tr>
                    //                         </thead>
                    //                         <tbody>
                    //                             <tr>
                    //                                 <td style="border: 1px solid black;">${order.order_number}</td>
                    //                                 <td style="border: 1px solid black;">${order.user.fullName}</td>
                    //                                 <td style="border: 1px solid black;">${timestamp_to_date_with_time_heb(firestoreTimestamp)}</td>
                    //                             </tr>    
                    //                         </tbody>
                    //                     </table>
                    //                     <h2>הערה: ${order.comment ? order.comment : 'לא צויין'}</h2>
                    //                     <h3>ההזמנה כוללת את המוצרים הבאים:</h3>
                    //                     <table style="width: 100%; border-collapse: collapse; border: 1px solid black;">
                    //                         <thead>
                    //                             <tr>
                    //                                 <th style="border: 1px solid black;">ברקוד</th>
                    //                                 <th style="border: 1px solid black;">שם</th>
                    //                                 <th style="border: 1px solid black;">כמות באריזה</th>
                    //                                 <th style="border: 1px solid black;">מארזים</th>
                    //                                 <th style="border: 1px solid black;">סה"כ יחידות</th>
                    //                             </tr>
                    //                         </thead>
                    //                         <tbody>
                    //                             ${order.items.map(item =>
                    //                             `<tr>
                    //                                 <td style="border: 1px solid black;">${item.sku}</td>
                    //                                 <td style="border: 1px solid black;">${item.name}</td>
                    //                                 <td style="border: 1px solid black;">${item.pac_amount}</td>
                    //                                 <td style="border: 1px solid black;">${(item.qnt / item.pac_amount) % 1 === 0 ? (item.qnt / item.pac_amount).toFixed(0) : (item.qnt / item.pac_amount).toFixed(2)}</td>
                    //                                 <td style="border: 1px solid black;">${item.qnt} ${item.pac_fac}'</td>
                    //                             </tr>`
                    //                             ).join('')} 
                    //                         </tbody>
                    //                     </table>
                    //                 </div>
            
                    //             `
                    //             // const email_list = await get_emails_list('sales')
            
                    //             // await sendEmail(['mosescorcias@gmail.com',...email_list],html,'שמרים - מערכת הזמנות',`הזמנה חדשה- לקוח: ${order.user.fullName}`)

                    //             clean_qnt_and_type()
                    //             is_panding.value = false
                    //             emit('close')
                    //             alert('success','ההזמנה שוגרה')
                    //         }catch(err){
                    //             slide_pop_notification('error', err.message);
                    //             is_panding.value = false
                    //         }
                    //     }
                    // })
                }catch(err){
                    console.error(err.message);
                    slide_pop_error(err.message)
                    is_panding.value = false
                }
            }

            const handle_submit = (user_data) => {
                console.log(user_data);
                const userData = {
                    ...user_data,
                    comment:comment.value || 'לא צויין'
                }
                emit('submit',userData)
            }

        
            return{
                is_panding,
                handle_next_step,
                comment,
                show_user_details_form,
                handle_submit,
                price_per_item
            }
        }
    }
</script>

<style scoped>
    .order-summary{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
        z-index: 1000;
        padding: 5px;
    }
    .order-summary-box{
        position: relative;
        width: 100%;
        max-width: 600px;
        height: 80%;
        background: #fff;
        border-radius: 10px;
        margin: 0 auto;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }
    .order-summary-box-header,.order-summary-box-footer{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        overflow: auto;
    }
    .order-summary-box-content{
        width: 100%;
        flex: 1;
        overflow-y: auto;
        scrollbar-width: none; /* For Firefox */
        -ms-overflow-style: none; /* For Internet Explorer and Edge */
        padding: 0 5px;
    }
    .order-summary-box-content::-webkit-scrollbar {
            display: none; /* For Chrome, Safari, and Opera */
    }
    .order-summary-box-describe{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 5px;
        overflow-y: auto;
    
        
    }

    .order-summary-box-total{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 16px;
    }
    table th {
        padding-top: 2px;
        padding-bottom: 2px;
        text-align: center;
        background-color: var(--purple);
        color: white;
        position: sticky;
        top: 0;
        font-size: 18px;
    }
    @media only screen and (max-width: 600px){
        table th {
            position: static;
        }
    }

    .exit-btn{
        position: absolute;
        width: 30px;
        height: 30px;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        color: #fff;
        background: crimson;
        border-radius: 50%;
        cursor: pointer;
    }


</style>